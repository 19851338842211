import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "../../components/Container"
import LivingAreaGalleryComponent from "../../components/gallery/LivingAreaGalleryComponent"

const LivingAreaGalleryPage = () => (
  <Layout>
    <SEO title="Gallery" />
    <Container
      noTitle
      style={{
        background: `#52575a`,
        color: `white`,
      }}
      whitetitle
    >
      <LivingAreaGalleryComponent />
    </Container>
  </Layout>
)

export default LivingAreaGalleryPage
